import React, { useState, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import SupabaseService from '../services/SupabaseService';
import { useAuth } from '../context/AuthContext';
import Layout from '../components/Layout';
import '../styles/Register.css';

const Register: React.FC = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<React.ReactNode>('');
  const [success, setSuccess] = useState<string | null>(null);
  const navigate = useNavigate();
  const { state, isInitialized } = useAuth();

  const handleRegister = async (event: FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setSuccess(null);
    setErrorMessage('');

    try {
      await new Promise((resolve) => setTimeout(resolve, 500));

      const result = await SupabaseService.registerUser(email);
      if (result && 'error' in result && result.error) {
        if (result.error.message.includes('ineligible')) {
          setErrorMessage(
            <>
              {result.error.message}{' '}
              <a
                href="https://polychord.io/contact.php"
                target="_blank"
                rel="noopener noreferrer"
                className="error-link"
              >
                here.
              </a>
            </>,
          );
        } else {
          setErrorMessage(result.error.message);
        }
      } else {
        setSuccess(
          'If you do not have an account with us, you will receive a registration link. Please check your inbox.',
        );
      }
    } catch {
      setErrorMessage('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleLogoutAndRedirect = async () => {
    await SupabaseService.logout();
    navigate('/register');
  };

  const renderRegisterForm = () => (
    <div className="register-box">
      <h2>Register for PolyTrack</h2>
      <p>Enter your email to receive a registration link</p>
      <form onSubmit={handleRegister}>
        <label htmlFor="email" className="field-label">
          Email
        </label>
        <input
          id="email"
          type="email"
          placeholder="mail@example.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          aria-label="Email address"
        />
        <button type="submit" disabled={loading}>
          {loading ? (
            <>
              <svg
                className="spinner"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <circle
                  className="spinner-circle"
                  cx="12"
                  cy="12"
                  r="10"
                  strokeDasharray="31.4 31.4"
                  strokeDashoffset="0"
                  strokeLinecap="round"
                />
              </svg>
              Processing...
            </>
          ) : (
            <>
              <svg
                className="icon"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8
                     m-2 10H5a2 2 0 
                     01-2-2V8a2 2 0 
                     012-2h14a2 2 0 
                     012 2v8a2 2 0 
                     01-2 2z"
                />
              </svg>
              Register
            </>
          )}
        </button>
      </form>
      {success && <div className="bubble bubble-success">{success}</div>}
      {errorMessage && (
        <div className="bubble bubble-error">{errorMessage}</div>
      )}
      <p className="switch-text">
        Already have an account? <a href="/login">Login here</a>
      </p>
    </div>
  );

  const renderRegistration = () => (
    <div className="register-box">
      <h2>Registration</h2>
      <p>You already have an account.</p>
      <p>If you wish to register a new account, please</p>
      <button onClick={handleLogoutAndRedirect} className="logout-button">
        Log out
      </button>
    </div>
  );

  if (!isInitialized) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <div className="register">
        <div className="register-content">
          {state === 'logged-in' ? renderRegistration() : renderRegisterForm()}
        </div>
        <video
          autoPlay
          muted
          loop
          disablePictureInPicture
          playsInline
          poster=""
          className="register-background"
        >
          <source
            src="/assets/digital-animation.mp4"
            type="video/mp4; codecs=avc1.4D401E"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    </Layout>
  );
};

export default Register;
